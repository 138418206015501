
.imgModal {
    width: 450px; 
    height: 350px;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
}



@media (max-width: 576px) {
    .imgModal{
        width: 100%;
        height: auto;
    }
    .modal-dialog {
        max-width: 90%;
        margin: 10px auto;
    }

    .modal-content {
        border-radius: 5px;
    }
    
  

    .product-info-description {
        font-size: 14px;
        word-break: break-word; 
    }

    .modal-footer {
        font-size: 14px;
        white-space: nowrap;
    }
} 



.added-to-cart {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    position: relative;
    overflow: hidden;
}

.added-to-cart::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, 
        rgba(255,255,255,0) 0%, 
        rgba(255,255,255,0.3) 50%, 
        rgba(255,255,255,0) 100%);
    animation: shine 0.5s linear;
}

@keyframes shine {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
}